/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: accountManagerRouters.js
Description: This file contains the router configuration useful for handling the navigation for account manager site.
*/
import indexSkrettingTechnician from "@/views/indexSkrettingTechnician";
import view404 from "@/views/view404";
export const SkrettingTechnicianUsers = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/skrettingTechnician/CustomerDetailsTable"
  );

const doesUserCanAccess = function(to, from, next) {
  return next();
};

export default {
  path: "/skretting-technician",
  component: indexSkrettingTechnician,
  children: [
    {
      path: "/",
      redirect: "users"
    },
    {
      path: "users",
      name: "users",
      component: SkrettingTechnicianUsers,
      meta: {
        langKey: "DL_customers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "404",
      name: "admin404",
      component: view404,
      meta: {
        langKey: "PondLogs"
      }
    }
  ]
};
