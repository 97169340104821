/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: user.js
Description: This file contains the model, functions of user object used all over the pondlogs customer site
*/
class User {
  constructor() {
    this.account_status = false;
    this.allowed_locations = [];
    this.avatar = "";
    this.confirm_password = "";
    this.country = { name: "", code: "" };
    this.created_at = "";
    this.email = "";
    this.email_verified = "";
    this.first_name = "";
    this.last_name = "";
    this.password = "";
    this.permissions = [];
    this.phone = "";
    this.phone_verified = "";
    this.source = "";
    this.timezone = "";
    this.updated_at = "";
    this.user_type = "";
    this.is_admin = false;
    this.status = "ACTIVE";
  }

  getSignupFields() {
    return {
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      password: this.password,
      confirm_password: this.confirm_password,
      country: this.country,
      country_code: this.country_code,
      phone: this.phone,
      timezone: this.timezone
    };
  }

  setErrResponseFields(responseObj) {
    Object.keys(responseObj).forEach(key => {
      this[key] = responseObj[key];
    });
  }

  castToUserObj(responseData) {
    Object.keys(responseData).forEach(key => {
      this[key] = responseData[key];
    });
  }

  static setDetails(
    first_name,
    last_name,
    email,
    phone,
    country,
    country_code,
    timezone
  ) {
    var user = new User();
    user.first_name = first_name;
    user.last_name = last_name;
    user.email = email;
    user.phone = phone;
    user.country = country;
    user.country_code = country_code;
    user.timezone = timezone;
    return user;
  }
}

export default User;
