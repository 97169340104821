/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pageAccessManager.js
Description: This file contains common functions related to user access management.
*/
import { getType } from "@/utils/commonUtils";
import { cloneDeep } from "lodash";
export const FTPermissions = [
  "MANAGE_OVERVIEW",
  "MANAGE_CULTURE",
  "MANAGE_SCHEDULES",
  "MANAGE_STOCK",
  "MANAGE_MEDICINE",
  "MANAGE_DEVICES",
  "MANAGE_ABW",
  "MANAGE_HARVEST",
  "MANAGE_LAB_TESTS"
];
export const FTSchEditPermissions = [
  "MANAGE_OVERVIEW",
  "MANAGE_CULTURE",
  "MANAGE_SCHEDULES",
  "MANAGE_STOCK",
  "MANAGE_MEDICINE",
  "MANAGE_DEVICES",
  "MANAGE_ABW",
  "MANAGE_HARVEST",
  "MANAGE_LAB_TESTS",
  "MANAGE_EDIT_SCHEDULES"
];
const orderForDefaultPages = [
  "MANAGE_OVERVIEW",
  "MANAGE_CULTURE",
  "MANAGE_SCHEDULES",
  "MANAGE_DEVICES",
  "MANAGE_ABW",
  "MANAGE_MEDICINE",
  "MANAGE_HARVEST",
  "MANAGE_STOCK",
  "MANAGE_LAB_TESTS"
];
export const FTPermissionsToActions = [
  { name: "MANAGE_OVERVIEW", availableActions: ["VIEW"] },
  { name: "MANAGE_CULTURE", availableActions: ["VIEW", "UPDATE"] },
  { name: "MANAGE_SCHEDULES", availableActions: ["VIEW", "UPDATE", "DELETE"] },
  { name: "MANAGE_STOCK", availableActions: ["VIEW", "UPDATE", "DELETE"] },
  { name: "MANAGE_MEDICINE", availableActions: ["VIEW", "UPDATE", "DELETE"] },
  { name: "MANAGE_DEVICES", availableActions: ["VIEW", "UPDATE", "DELETE"] },
  { name: "MANAGE_ABW", availableActions: ["VIEW", "UPDATE", "DELETE"] },
  { name: "MANAGE_HARVEST", availableActions: ["VIEW", "UPDATE", "DELETE"] },
  { name: "MANAGE_LAB_TESTS", availableActions: ["VIEW", "UPDATE", "DELETE"] }
];
export const permissionActionMap = (initialValue = false) =>
  FTPermissions.reduce((acc, curr) => {
    acc[curr] = {
      VIEW: initialValue,
      UPDATE: initialValue,
      DELETE: initialValue
    };
    return acc;
  }, {});
const permissionToPageMapper = {
  MANAGE_OVERVIEW: {
    path: "/user/dashboard",
    tab: "dashboardTab",
    hasTab: true
  },
  MANAGE_CULTURE: {
    path: "/user/dashboard",
    tab: "pondInfoTab",
    hasTab: true
  },
  MANAGE_SCHEDULES: {
    path: "/user/feeding",
    tab: "feedInfoTab",
    hasTab: true
  },
  MANAGE_STOCK: {
    path: "/user/stock",
    tab: "medicine",
    hasTab: true
  },
  MANAGE_MEDICINE: {
    path: "/user/culture-medicine",
    hasTab: false
  },
  MANAGE_DEVICES: {
    path: "/user/battery",
    hasTab: false
  },
  MANAGE_ABW: {
    path: "/user/abw",
    hasTab: false
  },
  MANAGE_HARVEST: {
    path: "/user/yield",
    hasTab: false
  },
  MANAGE_LAB_TESTS: {
    path: "/user/lab-tests",
    hasTab: false
  }
};
const canAccess = function(permissions, userAllowedPermissionsSet) {
  return (permissions || []).every(permission => {
    return userAllowedPermissionsSet.has(permission);
  });
};
export const isValidTabName = function(
  tab,
  items,
  userViewPermissions,
  userType
) {
  if (tab === "") return false;
  const listOfTabString = `|${Object.keys(items).join("|")}|`;
  const doTabNameExist = listOfTabString.includes(`|${tab}|`);
  if (!doTabNameExist) {
    return false;
  }
  const doUserHasRequiredPermission = canUserViewTab(
    items[tab],
    userViewPermissions,
    userType
  );
  if (!doUserHasRequiredPermission) {
    return false;
  }
  return true;
};
export const canUserViewTab = (tab, userViewPermissions, userType) => {
  const keyToValidateAccess = tab.keyToValidateAccess;
  let authorizations = tab[keyToValidateAccess];
  if (getType(authorizations) === "object") {
    authorizations = authorizations.VIEW;
  }
  const methodToValidate =
    keyToValidateAccess === "PERMISSIONS"
      ? x => userViewPermissions[x]
      : x => x === userType;
  return authorizations.some(methodToValidate);
};
export const getFirstTabBasedOnPermissions = function(
  items,
  userViewPermissions,
  userType
) {
  const selectTab = Object.values(items).find(currItem =>
    canUserViewTab(currItem, userViewPermissions, userType)
  );
  return (selectTab || { name: "" }).name;
};
export const getUserSelectedTabBasedOnPermissions = function(
  items,
  userViewPermissions,
  userType,
  tabName
) {
  const selectedTabData = items[tabName];
  const selectTab = selectedTabData
    ? canUserViewTab(selectedTabData, userViewPermissions, userType)
    : false;

  if (selectTab) {
    return selectedTabData.name;
  } else {
    return getFirstTabBasedOnPermissions(items, userViewPermissions, userType);
  }
};
export const permissionsToBKPermissions = {
  MANAGE_OVERVIEW: {
    VIEW: [
      "VIEW_PONDS",
      "VIEW_STATS",
      "VIEW_POND_SUMMARY",
      "VIEW_POND_ALERTS",
      "VIEW_WATER_QUALITY_SUMMARY",
      "VIEW_ABW"
    ],
    UPDATE: [],
    DELETE: []
  },
  MANAGE_MEDICINE: {
    VIEW: ["VIEW_PONDS", "VIEW_MEDICINES", "VIEW_RESOURCES"],
    UPDATE: ["UPDATE_MEDICINES", "UPDATE_RESOURCES"],
    DELETE: ["DELETE_MEDICINES", "DELETE_RESOURCES"]
  },
  MANAGE_HARVEST: {
    VIEW: ["VIEW_POND_SUMMARY", "VIEW_PONDS", "VIEW_ABW", "VIEW_HARVESTS"],
    UPDATE: ["UPDATE_HARVESTS"],
    DELETE: ["DELETE_HARVESTS"]
  },
  MANAGE_ABW: {
    VIEW: ["VIEW_POND_SUMMARY", "VIEW_PONDS", "VIEW_ABW", "VIEW_CULTURE"],
    UPDATE: ["UPDATE_ABW"],
    DELETE: ["DELETE_ABW"]
  },
  MANAGE_POND_GUARD: {
    VIEW: ["VIEW_POND_GUARDS"],
    UPDATE: ["UPDATE_POND_GUARDS"],
    DELETE: ["DELETE_POND_GUARDS"]
  },
  MANAGE_POND_MOTHER: {
    VIEW: ["VIEW_POND_MOTHERS"],
    UPDATE: ["UPDATE_POND_MOTHERS"],
    DELETE: ["DELETE_POND_MOTHERS"]
  },
  MANAGE_SHRIMP_TALK: {
    VIEW: ["VIEW_SHRIMP_TALKS"],
    UPDATE: ["UPDATE_SHRIMP_TALKS"],
    DELETE: ["DELETE_SHRIMP_TALKS"]
  },
  MANAGE_GATEWAY: {
    VIEW: ["VIEW_GATEWAYS"],
    UPDATE: ["UPDATE_GATEWAYS"],
    DELETE: []
  },
  MANAGE_CULTURE: {
    VIEW: [
      "VIEW_POND_SUMMARY",
      "VIEW_POND_ALERTS",
      "VIEW_PONDS",
      "VIEW_SCHEDULES",
      "VIEW_MANUAL_FEED",
      "VIEW_POND_MOTHERS",
      "VIEW_POND_GUARDS",
      "VIEW_SHRIMP_TALKS",
      "VIEW_GATEWAYS",
      "VIEW_WATER_QUALITY_SUMMARY",
      "VIEW_RESOURCES",
      "VIEW_CULTURE"
    ],
    UPDATE: ["UPDATE_PONDS", "UPDATE_MANUAL_FEED"],
    DELETE: []
  },
  MANAGE_SCHEDULES: {
    VIEW: [
      "VIEW_POND_GUARDS",
      "VIEW_SCHEDULES",
      "VIEW_PONDS",
      "VIEW_MANUAL_FEED",
      "VIEW_RESOURCES",
      "VIEW_GATEWAYS",
      "VIEW_POND_MOTHERS",
      "VIEW_SHRIMP_TALKS",
      "VIEW_CULTURE"
    ],
    UPDATE: [
      "UPDATE_SCHEDULES",
      "UPDATE_SHRIMP_TALKS",
      "UPDATE_POND_MOTHERS",
      "UPDATE_GATEWAYS",
      "UPDATE_MANUAL_FEED"
    ],
    DELETE: [] // "DELETE_SCHEDULES"
  },
  MANAGE_DEVICES: {
    VIEW: [
      "VIEW_POND_GUARDS",
      "VIEW_POND_MOTHERS",
      "VIEW_SHRIMP_TALKS",
      "VIEW_GATEWAYS",
      "VIEW_POND_ALERTS",
      "VIEW_PONDS",
      "VIEW_WATER_QUALITY_SUMMARY",
      "VIEW_MANUAL_FEED",
      "VIEW_SCHEDULES",
      "VIEW_RESOURCES"
    ],
    UPDATE: [
      "UPDATE_POND_GUARDS",
      "UPDATE_POND_MOTHERS",
      "UPDATE_SHRIMP_TALKS",
      "UPDATE_GATEWAYS",
      "UPDATE_MANUAL_FEED"
    ],
    DELETE: ["DELETE_POND_GUARDS", "DELETE_POND_MOTHERS", "DELETE_SHRIMP_TALKS"]
  },
  MANAGE_LAB_TESTS: {
    VIEW: ["VIEW_LAB_TEST", "VIEW_LABS", "VIEW_PONDS"],
    UPDATE: ["UPDATE_LAB_TEST", "UPDATE_LABS"],
    DELETE: ["DELETE_LAB_TEST", "DELETE_LABS"]
  },
  MANAGE_STOCK: {
    VIEW: ["VIEW_STOCK", "VIEW_RESOURCES"],
    UPDATE: ["UPDATE_STOCK", "UPDATE_RESOURCES"],
    DELETE: ["DELETE_STOCK", "DELETE_RESOURCES"]
  },
  MANAGE_EDIT_SCHEDULES: {
    VIEW: [],
    UPDATE: ["UPDATE_SCHEDULES", "UPDATE_SHRIMP_TALKS", "UPDATE_POND_MOTHERS"],
    DELETE: ["DELETE_SCHEDULES"]
  }
};

export const userTypes = {
  admin: "ADMIN",
  pond_owner: "POND_OWNER",
  pond_worker: "POND_WORKER",
  pond_technician: "POND_TECHNICIAN",
  pond_manager: "POND_MANAGER",
  no_access: "NO_ACCESS",
  super_admin: "SUPER_ADMIN",
  dealer: "DEALER",
  account_manager: "ACCOUNT_MANAGER",
  skretting_technician: "ACCOUNT_MANAGER_SUB_USER"
};
export const permissionsToStringMap = {
  MANAGE_ABW: "MANAGE_ABW",
  MANAGE_CULTURE: "MANAGE_CULTURE",
  MANAGE_DEVICES: "MANAGE_DEVICES",
  MANAGE_HARVEST: "MANAGE_HARVEST",
  MANAGE_MEDICINE: "MANAGE_MEDICINE",
  MANAGE_OVERVIEW: "MANAGE_OVERVIEW",
  MANAGE_SCHEDULES: "MANAGE_SCHEDULES",
  MANAGE_LAB_TESTS: "MANAGE_LAB_TESTS",
  MANAGE_STOCK: "MANAGE_STOCK",
  MANAGE_EDIT_SCHEDULES: "MANAGE_EDIT_SCHEDULES"
};
export const groupPermissions = function(permissions) {
  const userAllowedPermissionsSet = new Set(permissions);
  const permissionsActionMap = cloneDeep(permissionActionMap());
  FTPermissions.reduce((accFTPermissions, currFTPermission) => {
    ["VIEW", "UPDATE", "DELETE"].reduce((accAction, currAction) => {
      if (permissionsToBKPermissions[currFTPermission]) {
        const bkPermissions =
          permissionsToBKPermissions[currFTPermission][currAction];
        accAction[currAction] = bkPermissions.length
          ? canAccess(bkPermissions, userAllowedPermissionsSet)
          : false;
      }
      return accAction;
    }, accFTPermissions[currFTPermission]);
    return accFTPermissions;
  }, permissionsActionMap);
  return permissionsActionMap;
};
export const defaultPageBasedOnPermissions = function(viewPermissions) {
  const routeKey = orderForDefaultPages.find(
    FTPermission => viewPermissions[FTPermission]
  );
  if (!routeKey) return undefined;
  const pathObj = permissionToPageMapper[routeKey];
  if (pathObj.hasTab) {
    return { path: pathObj.path, tab: pathObj.tab };
  }
  return { path: pathObj.path };
};
export const userTypesWithAccess = [
  userTypes.admin,
  userTypes.pond_owner,
  userTypes.pond_manager,
  userTypes.pond_worker,
  userTypes.pond_technician
];
export const customerUserTypes = [
  userTypes.admin,
  userTypes.pond_owner,
  userTypes.pond_manager,
  userTypes.pond_worker,
  userTypes.pond_technician,
  userTypes.no_access,
  userTypes.skretting_technician,
  userTypes.account_manager
];
export const customerUserTypeToLangKey = {
  [userTypes.admin]: "Usrs_admin",
  [userTypes.pond_owner]: "Usrs_owner",
  [userTypes.pond_manager]: "Comn_manager",
  [userTypes.pond_worker]: "Usrs_pond_worker",
  [userTypes.pond_technician]: "Usrs_pond_technician",
  [userTypes.no_access]: "Comn_no_access",
  [userTypes.skretting_technician]: "Skretting_technician",
  [userTypes.account_manager]: "Account_manager"
};

export const highPriorityUserTypes = [
  userTypes.admin,
  userTypes.pond_owner,
  userTypes.pond_manager
];
export const adminUserTypes = [userTypes.admin, userTypes.pond_owner];
export default {
  groupPermissions,
  defaultPageBasedOnPermissions,
  permissionsToBKPermissions,
  permissionsToStringMap,
  FTPermissions,
  FTSchEditPermissions,
  userTypes,
  userTypesWithAccess,
  highPriorityUserTypes,
  canUserViewTab,
  isValidTabName,
  getFirstTabBasedOnPermissions,
  adminUserTypes,
  customerUserTypes,
  customerUserTypeToLangKey,
  FTPermissionsToActions,
  permissionActionMap
};
