import { $superAdminAxios } from "@/config/axios";

const state = {
  salesmanagers: [],
  salesExecutiveUsersData: []
};

const getters = {
  getSalesManagers: state => state.salesmanagers,
  getSalesExecutiveUserData(state) {
    return state.salesExecutiveUsersData;
  }
};

const mutations = {
  SET_SALES_MANAGER_DATA: function(state, salesmanagers) {
    state.salesmanagers = salesmanagers;
  },
  SET_APPEND_SALES_MANAGER_DATA: function(state, salesmanagers) {
    if (state.accountmanagers.length === 0) {
      state.salesmanagers = salesmanagers;
    } else {
      state.salesmanagers = state.salesmanagers.concat(salesmanagers);
    }
  },
  SET_SALES_EXECUTIVES_USERS_DATA: function(state, executiveUserData) {
    state.salesExecutiveUsersData = executiveUserData;
  },
  SET_ASSIGNED_EXECUTIVE_USERS: function(state, accountmanagerassignedusers) {
    state.accountmanagerassignedusers = accountmanagerassignedusers;
  }
};

const actions = {
  fetchAllSalesManagers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "email";
    params.order_type = "asc";
    const response = await $superAdminAxios.get(
      "admin/sales-executive-managers",
      {
        params
      }
    );
    const setter = infinite_scroll
      ? "SET_APPEND_SALES_MANAGER_DATA"
      : "SET_SALES_MANAGER_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.sales_executive_managers);
    return response.data;
  },
  fetchAllSalesExecutiveUsers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "email";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("admin/sales-executives", {
      params
    });
    context.commit(
      "SET_SALES_EXECUTIVES_USERS_DATA",
      response.data.salesExecutive
    );
    return response.data;
  },
  AddSalesManager: async (context, salesmanager) => {
    const response = await $superAdminAxios.post(
      "admin/sales-executive-managers",
      salesmanager
    );
    return response.data;
  },
  EditSalesManager: async (context, salesmanager) => {
    const response = await $superAdminAxios.patch(
      `admin/sales-executive-managers/${salesmanager._id}`,
      salesmanager
    );
    return response.data;
  },
  AddSalesExecutive: async (context, salesExecutive) => {
    const response = await $superAdminAxios.post(
      "admin/sales-executives",
      salesExecutive
    );
    return response.data;
  },
  EditSalesExecutive: async (context, salesExecutive) => {
    const response = await $superAdminAxios.patch(
      `admin/sales-executives/${salesExecutive._id}`,
      salesExecutive
    );
    return response.data;
  },
  fetchAlreadyAssingedExecutiveUsers: async (
    context,
    { salesmanager_id, params = {} }
  ) => {
    const response = await $superAdminAxios.get(
      `admin/sales-executive-managers/${salesmanager_id}/users`,
      { params }
    );
    context.commit("SET_ASSIGNED_EXECUTIVE_USERS", response.data.users);
    return response.data;
  },
  addExicutiveToSalesManger: async (context, payload) => {
    const response = await $superAdminAxios.post(
      "/admin/sales-executive-managers/users",
      payload
    );
    return response;
  },
  replaceSalesExicutive: async (context, payload) => {
    const response = await $superAdminAxios.post(
      "/admin/replace-sales-executive",
      payload
    );
    return response;
  },
  replaceSalesManager: async (context, payload) => {
    const response = await $superAdminAxios.post(
      "/admin/replace-sales-executive-manager",
      payload
    );
    return response;
  },
  removeAssignedExecutivesFromSalesManager: async (context, payload) => {
    const response = await $superAdminAxios.delete(
      "/admin/sales-executive-managers/users",
      {
        data: payload
      }
    );
    return response;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
