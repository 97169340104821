/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondInfo.js
Description: This file contains all the vuex store functions used in PondInfo page
*/
import FeedManagementService from "@/services/FeedManagementService";
import WaterQualityService from "@/services/WaterQualityService";
import ShrimpGrowthService from "@/services/ShrimpGrowthService";
import PondsService from "@/services/PondsService";
import dateUtils from "@/utils/dateUtils";
import { timeStrHHmmVal, alphaNumericComparator } from "@/utils/commonUtils";
import { cloneDeep } from "lodash";
import i18n from "@/config/i18n";
const minTimeISO = dateUtils.minTimeISO;
const pondInfoStore = {
  namespaced: true,
  state: {
    dashboardTabSelectedPondIds: [],
    selectedTab: "ACTIVE",
    currLocation: {},
    activePonds: [],
    inactivePonds: [],
    allPonds: [],
    selectedPonds: {
      ACTIVE: [],
      INACTIVE: []
    },
    pondDeviceDetails: [],
    scheduleFeed: [],
    doData: [],
    abwData: [],
    datesInGraph: {
      FEED: ["2019-10-20", "2019-10-20"],
      ABW: ["2019-10-20", "2019-10-20"],
      DO: ["2019-10-20", "2019-10-20"]
    },
    latestNABWValues: [],
    latestNDoTempValues: [],
    allGraphLoading: false,
    doRangeStartDate: "",
    doInDateRange: {},
    pondToColorMap: {},
    unAllocatedColors: [4, 3, 2, 1, 0], // as max selected ponds are five,
    isGraphPanelVisible: true,
    userTimeZoneString: "UTC",
    pgDevicesCount: 0
  },
  getters: {
    getGraphPanelStatus: state => {
      return state.isGraphPanelVisible;
    },
    getAllHyperLinkPonds: state => state.dashboardTabSelectedPondIds,
    getSelectedTab: state => state.selectedTab,
    getDatesInGraph: state => state.datesInGraph,
    getActivePonds: (state, getters) => {
      return state.activePonds.map(pond => {
        const newPond = cloneDeep(pond);
        const value = getters.getPondMode[newPond._id];
        const obj = {
          SHRIMP_TALK: "PM_automatic",
          HYBRID: "hybrid",
          FARMER: "Comn_schedule",
          MANUAL: "PM_sch_mode4",
          NO_PMS: "-"
        };

        newPond.mode = i18n.t(obj[value]);
        return newPond;
      });
    },
    getInactivePonds: (state, getters) => {
      return state.inactivePonds.map(pond => {
        const newPond = cloneDeep(pond);
        const value = getters.getPondMode[newPond._id];
        const obj = {
          SHRIMP_TALK: "PM_automatic",
          HYBRID: "hybrid",
          FARMER: "Comn_schedule",
          MANUAL: "PM_sch_mode4",
          NO_PMS: "-"
        };

        newPond.mode = i18n.t(obj[value]);
        return newPond;
      });
    },
    getAllPonds: (state, getters) => [
      ...getters.getActivePonds,
      ...getters.getInactivePonds
    ],
    getDoRangeStartDate: state => state.doRangeStartDate,
    getDoInDateRange: state => state.doInDateRange,
    getPondIdToLatestNABWValues: (state, getters) => {
      const defualtABW = {
        abw: 0,
        date: minTimeISO,
        awg: 0
      };
      const pondIdToLatestABWValues = state.latestNABWValues.reduce(
        (acc, curr) => {
          acc[curr._id] = [
            ...curr.latest_records,
            defualtABW,
            defualtABW,
            defualtABW,
            defualtABW
          ]
            .slice(0, 4)
            .sort((a, b) => {
              return dateUtils.dateComparator(a.date, b.date, true);
            });
          state.inactivePonds.map(pond => {
            if (
              curr._id === pond._id &&
              pond.status === "INACTIVE" &&
              acc[curr._id][acc[curr._id].length - 1].harvested_abw === true
            ) {
              acc[curr._id][acc[curr._id].length - 1].awg =
                acc[curr._id][acc[curr._id].length - 1].abw -
                acc[curr._id][acc[curr._id].length - 2].abw;
            }
          });
          return acc;
        },
        {}
      );
      return getters.getPondsBasedOnSelectedTab.reduce((acc, { _id }) => {
        if (!acc[_id]) {
          acc[_id] = Array(4).fill(defualtABW);
        }
        return acc;
      }, pondIdToLatestABWValues);
    },
    getLatestNDOTempValues: function(state) {
      return state.latestNDoTempValues;
    },
    getPgDeviceCount: function(state) {
      return state.pgDevicesCount;
    },
    getPondIdToLatestNDOTempValues: (state, getters) => {
      const latestNDoTempValues = getters.getLatestNDOTempValues;
      const defualtDoTemp = {
        do: undefined,
        temp: undefined,
        doConfig: {},
        tempConfig: {}
      };
      const result = {};
      for (var j = 0; j < latestNDoTempValues.length; j++) {
        const devices = [];

        const { data } = latestNDoTempValues[j];

        for (var i = 0; i < data.length; i++) {
          const {
            dissolved_oxygen,
            do_alerts_config,
            pond_guard_id,
            temperature,
            temperature_alerts_config,
            pond_guard_code
          } = data[i];
          const {
            critical_lower_limit: do_critical_lower_limit,
            lower_limit: do_lower_limit
          } = do_alerts_config[0];
          const {
            lower_limit: temp_lower_limt,
            upper_limit: temp_upper_limit,
            tolerance: temp_tolerance
          } = temperature_alerts_config[0];
          devices.push({
            do: dissolved_oxygen,
            temp: temperature,
            doConfig: {
              critical_lower_limit: do_critical_lower_limit,
              lower_limit: do_lower_limit
            },
            tempConfig: {
              lower_limit: temp_lower_limt,
              upper_limit: temp_upper_limit,
              tolerance: temp_tolerance
            },
            pgID: pond_guard_id,
            pgCode: pond_guard_code
          });
        }
        if (devices.length > 2) {
          state.pgDevicesCount = devices.length;
        }

        result[latestNDoTempValues[j]._id] = devices;
      }
      return getters.getPondsBasedOnSelectedTab.reduce((acc, { _id }) => {
        if (!acc[_id]) {
          acc[_id] = Array(1).fill(defualtDoTemp);
        }
        return acc;
      }, result);

      // return result;
    },
    getSummaryOfLatestNABWValues: function(state, getters) {
      const groupByIndexWithObjValueFrequency = Object.values(
        getters.getPondIdToLatestNABWValues
      ).reduce((acc, latest_records, index) => {
        latest_records
          .map(a => a.abw)
          .forEach((value, index) => {
            if (!acc[index]) {
              acc[index] = {};
            }
            if (!acc[index][value]) {
              acc[index][value] = 0;
            }
            acc[index][value] += 1;
          });
        return acc;
      }, {});
      const summary = Object.values(groupByIndexWithObjValueFrequency).map(
        (latestArrIndexValue, index) => {
          const sumOfAbwValueMultipliedByItsFreq = Object.keys(
            latestArrIndexValue
          ).reduce((acc, abwValue) => {
            const freqOfValue = latestArrIndexValue[abwValue];
            const value = +abwValue;
            return acc + value * freqOfValue;
          }, 0);
          const total = Object.values(latestArrIndexValue).reduce(
            (acc, curr) => acc + curr
          );
          return sumOfAbwValueMultipliedByItsFreq / total;
        }
      );
      return summary;
    },
    getSummaryOfLatestDOTemp: function(state, getters) {
      const totals = Object.values(getters.getPondIdToLatestNDOTempValues)
        .flat(1)
        .reduce(
          (acc, pg) => {
            if (pg.do === undefined || pg.temp === undefined) {
              return acc;
            }
            if (pg.do) {
              acc.do += pg.do;
            }
            if (pg.temp) {
              acc.temp += pg.temp;
            }
            acc.pgs++;
            return acc;
          },
          { do: 0, temp: 0, pgs: 0 }
        );
      return {
        do: (totals.do / totals.pgs).toFixed(1),
        temp: (totals.temp / totals.pgs).toFixed(1)
      };
    },
    getPondsBasedOnSelectedTab(state, getters) {
      const selectedTab = getters.getSelectedTab;
      const tabToGetter = {
        ACTIVE: "getActivePonds",
        INACTIVE: "getInactivePonds",
        ALL: "getAllPonds"
      };
      return getters[tabToGetter[selectedTab]];
    },
    selectedPondIds: (state, getters) => getters.selectedPonds.map(x => x._id),
    selectedPonds: (state, getters) => {
      const selectedTab = getters.getSelectedTab;
      if (selectedTab === "ALL") {
        return [...state.selectedPonds.ACTIVE, ...state.selectedPonds.INACTIVE];
      } else {
        return state.selectedPonds[selectedTab];
      }
    },
    doesUserLocationHasPgsDetails(state) {
      const pondDeviceDetails = state.pondDeviceDetails;
      return pondDeviceDetails.some(curr => {
        const pondGuards = curr.pond_guards;
        return pondGuards.length > 0;
      });
    },
    scheduleFeed: state => state.scheduleFeed,
    doData: state => state.doData,
    abwData: state => state.abwData,
    getParamsObj: function(state, getters) {
      const date = getters.getDatesInGraph;
      const pond_ids = getters.getSelectedPondsBasedOnTabSelected;
      return {
        from_date: date[0],
        to_date: date[1],
        pond_ids
      };
    },
    getAllGraphLoading: state => state.allGraphLoading,
    getMinStartCultureDate: (state, getters, rootState, rootGetters) => {
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const minDate = dateUtils.min(
        getters.selectedPonds.map(x => new Date(x.cultivation_date))
      );
      return dateUtils.isValid(minDate)
        ? dateUtils.formatUserUTCISOToUTCISO(
            minDate.toISOString(),
            userTimeZoneString
          )
        : minTimeISO;
    },
    getDateRangeForFeedGraph: state => {
      return state.datesInGraph.FEED;
    },
    getDateRangeForABWGraph: state => {
      return state.datesInGraph.ABW;
    },
    getDateRangeForDOGraph: state => {
      return state.datesInGraph.DO;
    },
    getCurrLocation: state => {
      return state.currLocation;
    },
    getPondColorIds: state => {
      return state.pondToColorMap;
    },
    getPondMode: state => {
      const pondDeviceDetails = state.pondDeviceDetails;
      return pondDeviceDetails.reduce((acc, curr) => {
        const pondMothers = curr.pond_mothers;
        if (pondMothers.length === 0) {
          acc[curr._id] = "NO_PMS";
          return acc;
        }
        const mode = [...new Set(curr.pond_mothers.map(x => x.managed_by))];
        if (mode.length === 1) {
          acc[curr._id] = mode[0];
          return acc;
        }
        acc[curr._id] = "HYBRID";
        return acc;
      }, {});
    }
  },
  mutations: {
    SET_SELECTED_TAB: function(state, selectedTab) {
      state.selectedTab = selectedTab;
    },
    SET_USER_TIME_ZONE_STRING: function(state, userTimeZoneString) {
      state.userTimeZoneString = userTimeZoneString;
    },
    SET_DATE_CHANGE(state, { graph_key, date }) {
      state.datesInGraph[graph_key] = date;
    },
    SET_ACTIVE_PONDS_DATA(state, pondsData) {
      const userTimeZoneString = state.userTimeZoneString;
      if (pondsData != null) {
        pondsData.forEach(pond => {
          pond.status = "ACTIVE";
          pond.do = [];
          pond.temp = [];
          pond.cultivation_date =
            pond.cultivation_date ||
            dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
          pond.size = +pond.size;
        });
        state.activePonds = pondsData;
      } else {
        state.activePonds = [];
      }
    },
    SET_INACTIVE_PONDS_DATA(state, pondsData) {
      const userTimeZoneString = state.userTimeZoneString;
      if (pondsData != null) {
        pondsData.forEach(pond => {
          pond.status = "INACTIVE";
          pond.cultivation_date =
            pond.cultivation_date ||
            dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
          pond.size = +pond.size;
        });
        state.inactivePonds = pondsData;
      } else {
        state.inactivePonds = [];
      }
    },
    SELECT_PONDS: (state, ponds) => {
      const selectedTabName = state.selectedTab;
      if (selectedTabName === "ALL") {
        state.selectedPonds = ponds.reduce(
          (acc, pond) => {
            acc[pond.status].push(pond);
            return acc;
          },
          { ACTIVE: [], INACTIVE: [] }
        );
      } else {
        state.selectedPonds[selectedTabName] = [...ponds];
      }
      state.selectedPonds = Object.assign({}, state.selectedPonds);
    },
    SET_SCHEDULE_FEED: (state, feedData) => {
      state.scheduleFeed = feedData;
    },
    SET_DO: (state, doData) => {
      state.doData = doData;
    },
    SET_ABW: (state, abwData) => {
      state.abwData = abwData;
    },
    SET_GRAPH_DATE: (state, { graphType, dateRange }) => {
      state.datesInGraph[graphType] = dateRange;
      state.datesInGraph = Object.assign({}, state.datesInGraph);
    },
    SET_CURR_LOCATION: (state, currLocation) => {
      state.currLocation = currLocation;
    },
    SET_ALL_GRAPH_LOADING: (state, loadingStatus) => {
      state.allGraphLoading = loadingStatus;
    },
    SET_LATEST_N_ABW_VALUES: (state, latestNABWValues) => {
      state.latestNABWValues = latestNABWValues;
    },
    SET_LATEST_DO_TEMP_VALUES: (state, latestNDOTempValues) => {
      state.latestNDoTempValues = latestNDOTempValues;
    },
    SET_DO_RANGE_START_DATE: (state, date) => {
      state.doRangeStartDate = date;
    },
    SET_DO_IN_DATE_RANGE: (state, data) => {
      state.doInDateRange = data;
    },
    DELETE_POND_COLOR: (state, pondId) => {
      const color = state.pondToColorMap[pondId];
      delete state.pondToColorMap[pondId];
      state.unAllocatedColors.push(color);
    },
    SET_POND_COLOR: (state, arrPondIds) => {
      const mapPondColor = state.pondToColorMap;
      const unAllocatedColors = state.unAllocatedColors;
      arrPondIds.forEach(pondId => {
        if (isNaN(+mapPondColor[pondId])) {
          const color = unAllocatedColors.pop();
          mapPondColor[pondId] = color;
        }
      });
      state.pondToColorMap = Object.assign({}, mapPondColor);
    },
    SET_DASHBOARD_TAB_POND_IDS: (state, arrPondIds) => {
      state.dashboardTabSelectedPondIds = arrPondIds;
    },
    SET_POND_DEVICE_DETAILS: function(state, payload) {
      state.pondDeviceDetails = payload;
    },
    SET_SHOW_GRAPH_PANEL: function(state, visibilityStatus) {
      state.isGraphPanelVisible = visibilityStatus;
    }
  },
  actions: {
    initUserTimeZoneString: function(context) {
      context.commit(
        "SET_USER_TIME_ZONE_STRING",
        context.rootGetters["user/getUserTimeZoneString"]
      );
    },
    changeAllGraphLoadingStatus(context, status) {
      context.commit("SET_ALL_GRAPH_LOADING", status);
    },
    changeLocation: (context, locationObj) => {
      context.commit("SET_CURR_LOCATION", locationObj);
    },
    changeDashboardTabSelectedPondIds(context, arrPondIds) {
      context.commit("SET_DASHBOARD_TAB_POND_IDS", arrPondIds.pondIds);
      if (this.getSelectedTab === arrPondIds.tab) return;
      context.commit("SET_SELECTED_TAB", arrPondIds.tab);
    },
    changeSelectedTab: async (context, selectedTabName) => {
      context.commit("SET_ALL_GRAPH_LOADING", true);
      context.commit("SET_SELECTED_TAB", selectedTabName);
      context.dispatch("initUserTimeZoneString");
      const currLocation = context.getters.getCurrLocation;
      const query = {
        location_id: currLocation._id,
        get_all: true
      };
      const tabToStatus = {
        ACTIVE: ["ACTIVE"],
        INACTIVE: ["INACTIVE"],
        ALL: ["ACTIVE", "INACTIVE"]
      };
      const abwLatestQuery = {
        location_id: currLocation._id,
        pond_status: tabToStatus[selectedTabName],
        exclude_previous_harvest_data: true
      };
      const doTempQuery = {
        location_id: currLocation._id,
        exclude_previous_harvest_data: true
      };
      const keyToAction = {
        ACTIVE: "fetchActivePonds",
        INACTIVE: "fetchInactivePonds",
        ALL: "fetchAllPonds"
      };
      const keyToGetters = {
        ACTIVE: "getActivePonds",
        INACTIVE: "getInactivePonds",
        ALL: "selectedPonds"
      };
      try {
        await Promise.all([
          context.dispatch(keyToAction[selectedTabName], query),
          context.dispatch("fetchLatestNABWValues", abwLatestQuery),
          context.dispatch("fetchLatestDoAndTemp", doTempQuery),
          context.dispatch("fetchPondDeviceDetails")
        ]);
      } finally {
        if (selectedTabName === "ALL") {
          await context.dispatch("changeSelectedPonds", {
            selected_ponds: context.getters[keyToGetters[selectedTabName]]
          });
        } else {
          const hyperLinkedPonds = context.getters.getAllHyperLinkPonds || [];
          if (hyperLinkedPonds.length > 0) {
            var allPonds = context.getters[keyToGetters[selectedTabName]];

            var Ponds = allPonds.reduce(function(acc, obj) {
              const key = obj._id;
              acc[key] = obj;
              return acc;
            }, {});

            var hyperlinkrows = hyperLinkedPonds;

            var hyperlinkdata = hyperlinkrows.map(x => x._id);

            const filter = hyperlinkdata.map(x => Ponds[x]);

            const element = filter.sort((a, b) =>
              alphaNumericComparator(a.title, b.title)
            )[0];

            await context.dispatch("changeSelectedPonds", {
              selected_ponds: element ? [element] : []
            });
          } else {
            const element = cloneDeep(
              context.getters[keyToGetters[selectedTabName]] || []
            ).sort((a, b) => alphaNumericComparator(a.title, b.title))[0];

            await context.dispatch("changeSelectedPonds", {
              selected_ponds: element ? [element] : []
            });
          }
        }
        context.commit("SET_ALL_GRAPH_LOADING", false);
      }
    },
    changeSelectedPonds: async (context, { selected_ponds, deleted_pond }) => {
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      context.commit("SET_ALL_GRAPH_LOADING", true);
      if (deleted_pond) {
        context.commit("DELETE_POND_COLOR", deleted_pond._id);
      }
      context.commit("SELECT_PONDS", selected_ponds);
      context.commit(
        "SET_POND_COLOR",
        selected_ponds.map(x => x._id)
      );
      const minCultureDate = dateUtils.utcToZonedTime(
        context.getters.getMinStartCultureDate,
        userTimeZoneString
      );
      const currTimeInGivenTZ = dateUtils.getCurrDateInGivenTZ(
        userTimeZoneString
      );
      const minStartDates = {
        ABW: minCultureDate,
        FEED: minCultureDate,
        DO: minCultureDate
      };
      [
        ["ABW", 4, "weeks", dateUtils.differenceInWeeks],
        ["DO", 24, "hours", dateUtils.differenceInHours]
      ].forEach(record => {
        if (record[3](currTimeInGivenTZ, minCultureDate) > record[1]) {
          minStartDates[record[0]] = dateUtils.subtract(currTimeInGivenTZ, {
            [record[2]]: record[1]
          });
        }
      });
      ["FEED", "ABW", "DO"].forEach(graphType => {
        context.commit("SET_GRAPH_DATE", {
          graphType,
          dateRange: [
            dateUtils.formatTZ(minStartDates[graphType], "yyyy-MM-dd", {
              timeZone: userTimeZoneString
            }),
            dateUtils.formatTZ(currTimeInGivenTZ, "yyyy-MM-dd", {
              timeZone: userTimeZoneString
            })
          ]
        });
      });
      if (selected_ponds.length > 0) {
        await Promise.all([
          context.dispatch("fetchDO"),
          context.dispatch("fetchABW"),
          context.dispatch("fetchScheduleFeedData")
        ]);
      } else {
        context.commit("SET_ABW", []);
        context.commit("SET_DO", []);
        context.commit("SET_SCHEDULE_FEED", []);
      }
      context.commit("SET_ALL_GRAPH_LOADING", false);
    },
    changeDateRangeInGraph: async (context, data = {}) => {
      context.commit("SET_DATE_CHANGE", data);
      const { graph_key } = data;
      const params = context.getters.selectedPondIds;
      if (params.length > 0) {
        if (graph_key === "DO") {
          await context.dispatch("fetchDO", { pond_ids: params });
        } else if (graph_key === "FEED") {
          await context.dispatch("fetchScheduleFeedData", { pond_ids: params });
        } else {
          await context.dispatch("fetchABW", { pond_ids: params });
        }
      }
    },
    changeShowGraphsPanelStatus: function(context, visibilityStatus) {
      context.commit("SET_SHOW_GRAPH_PANEL", visibilityStatus);
    },
    fetchActivePonds: async (context, params = {}) => {
      const response = await PondsService.fetchActivePonds(params);
      context.commit("SET_ACTIVE_PONDS_DATA", response.data.ponds);
    },
    fetchLatestNABWValues: async (context, params = {}) => {
      const response = await ShrimpGrowthService.fetchLatestNABWValues(params);
      context.commit("SET_LATEST_N_ABW_VALUES", response.data.ponds);
    },
    fetchLatestDoAndTemp: async (context, params = {}) => {
      const response = await WaterQualityService.fetchLatestDOAndTemp(params);
      context.commit(
        "SET_LATEST_DO_TEMP_VALUES",
        response.data.pond_water_quality_summary
      );
    },
    fetchAllPonds: async (context, params = {}) => {
      await Promise.all([
        context.dispatch("fetchActivePonds", params),
        context.dispatch("fetchInactivePonds", params)
      ]);
    },
    fetchInactivePonds: async (context, params = {}) => {
      const response = await PondsService.fetchInactivePonds(params);
      context.commit("SET_INACTIVE_PONDS_DATA", response.data.ponds);
    },
    fetchScheduleFeedData: async context => {
      const date = context.getters.getDatesInGraph.FEED;
      const pondIds = context.getters.selectedPondIds;
      const params = {
        from_date: date[0],
        to_date: date[1],
        pond_ids: pondIds
      };
      const res = await FeedManagementService.fetchScheduleFeed(params);
      const formatedFeedData = res.data.schedules.map(
        ({ pond_id, title, data }) => {
          let feedList = data.map(({ date, feed }) => {
            const newDate = +new Date(date);
            return { newDate, feed };
          });
          let dispFeedList = data.map(({ date, dispensed_feed }) => {
            const newDate = +new Date(date);
            return { newDate, dispensed_feed };
          });
          feedList = feedList.sort((a, b) => a.newDate - b.newDate);
          dispFeedList = dispFeedList.sort((a, b) => a.newDate - b.newDate);
          return {
            pond_id,
            title,
            feedList,
            dispFeedList
          };
        }
      );
      context.commit("SET_SCHEDULE_FEED", formatedFeedData);
    },
    fetchDO: async context => {
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const date = context.getters.getDatesInGraph.DO;
      const pond_ids = context.getters.selectedPondIds;
      const params = {
        from_date: date[0],
        to_date: date[1],
        pond_ids
      };
      const res = await WaterQualityService.fetchDO(params);
      const { hourly_feed = [], pond_guard_data } = res.data;
      const ponds = [];
      for (var i = 0; i < hourly_feed.length; i++) {
        let title = "";
        let pondId = "";
        let doData = [];
        let feedData = [];
        title = pond_guard_data[i].title;
        pondId = pond_guard_data[i].pond_id;
        if (!title) {
          title = hourly_feed[i].title;
          pondId = hourly_feed[i].pond_id;
        }
        for (var j = 0; j < pond_guard_data[i].data.length; j++) {
          doData = [...doData, ...pond_guard_data[i].data[j].data];
        }
        doData = doData.reduce((acc, { date, dissolved_oxygen }) => {
          const tDate = dateUtils.zonedTimeToUtc(
            dateUtils.startOfHour(
              dateUtils.utcToZonedTime(
                dateUtils.formatUserUTCISOToUTCISO(date, userTimeZoneString),
                userTimeZoneString
              )
            ),
            "UTC"
          );
          const newDate = tDate.getTime();
          acc[newDate] = { newDate, dissolved_oxygen };
          return acc;
        }, {});
        for (let k = 0; k < hourly_feed[i].data.length; k++) {
          const timeSlots = hourly_feed[i].data[k].time_slots.map(
            ({ s_time, dispensed_feed }) => {
              const correctDate = dateUtils.startOfDay(
                dateUtils.utcToZonedTime(
                  dateUtils.formatUserUTCISOToUTCISO(
                    hourly_feed[i].data[k].date,
                    userTimeZoneString
                  ),
                  userTimeZoneString
                )
              );
              const newDate = dateUtils
                .zonedTimeToUtc(
                  dateUtils.add(correctDate, {
                    seconds: timeStrHHmmVal(s_time)
                  }),
                  "UTC"
                )
                .getTime();
              return { newDate, dispensed_feed };
            }
          );
          feedData = [...feedData, ...timeSlots];
        }
        feedData = feedData.sort((a, b) => {
          return a.newDate - b.newDate;
        });
        doData = Object.values(doData).sort((a, b) => a.newDate - b.newDate);
        ponds.push({
          title,
          pondId,
          doData,
          feedData
        });
      }
      context.commit("SET_DO", ponds);
    },
    fetchABW: async context => {
      const date = context.getters.getDatesInGraph.ABW;
      const pond_ids = context.getters.selectedPondIds;
      const params = {
        from_date: date[0],
        to_date: date[1],
        pond_ids
      };
      const res = await ShrimpGrowthService.fetchABW(params);
      const ponds = [];
      const pond = Object.values(res.data.result);
      for (var i = 0; i < pond.length; i++) {
        let title = "";
        let pondId = "";
        let abwData = [];
        let biomassData = [];

        abwData = [
          ...abwData,
          ...pond[i].map(({ abw, date, pond_id }) => {
            const formattedDate = +new Date(date);
            title = pond_id.title;
            pondId = pond_id._id;
            return { formattedDate, abw };
          })
        ];

        biomassData = [
          ...biomassData,
          ...pond[i].map(({ biomass, date }) => {
            const formattedDate = +new Date(date);
            return { formattedDate, biomass };
          })
        ];

        abwData = abwData.sort((a, b) => a.formattedDate - b.formattedDate);
        biomassData = biomassData.sort(
          (a, b) => a.formattedDate - b.formattedDate
        );
        ponds.push({ title, pondId, abwData, biomassData });
      }
      context.commit("SET_ABW", ponds);
    },
    fetchDoRangeStartDate: async (context, params = {}) => {
      const response = await WaterQualityService.fetchDoRangeStartDate(params);
      const { date } = response.data.data;
      context.commit("SET_DO_RANGE_START_DATE", date);
    },
    fetchDoInDateRange: async (context, data = {}) => {
      const { pgCode, config } = data;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const currentDate = dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
      const to_date = dateUtils
        .castBrowserDateToUserUTC(currentDate)
        .toISOString();
      const from_date = dateUtils
        .castBrowserDateToUserUTC(dateUtils.subtract(currentDate, { hours: 3 }))
        .toISOString();
      const params = {
        from_date,
        to_date
      };
      const response = await WaterQualityService.fetchDoInDateRange(
        params,
        pgCode
      );

      const pgData = response.data.pond_guard_data.map(({ data }) => {
        const { date, dissolved_oxygen, temperature: temp } = data;
        let color = "";

        if (config.critical_lower_limit) {
          if (dissolved_oxygen < config.critical_lower_limit) {
            color = "red";
          } else if (
            dissolved_oxygen >= config.critical_lower_limit &&
            dissolved_oxygen < config.lower_limit
          ) {
            color = "orange";
          } else if (dissolved_oxygen >= config.lower_limit) {
            color = "green";
          } else {
            color = "black";
          }
        } else {
          if (temp >= config.lower_limit && temp <= config.upper_limit) {
            color = "green";
          } else if (
            temp >= config.lower_limit - config.tolerance &&
            temp <= config.upper_limit + config.tolerance
          ) {
            color = "orange";
          } else if (
            temp < config.lower_limit - config.tolerance ||
            temp > config.upper_limit + config.tolerance
          ) {
            color = "red";
          } else {
            color = "black";
          }
        }

        return {
          date,
          color
        };
      });

      context.commit("SET_DO_IN_DATE_RANGE", pgData);
    },
    fetchPondDeviceDetails: async function(context) {
      const selectedTabToStatus = {
        ACTIVE: ["ACTIVE"],
        INACTIVE: ["INACTIVE"],
        ALL: ["ACTIVE", "INACTIVE"]
      };
      const currLocation = context.getters.getCurrLocation;
      const selectedTab = context.getters.getSelectedTab;
      const response = await PondsService.fetchAllPonds({
        get_all: true,
        status: selectedTabToStatus[selectedTab],
        include: ["pond_mothers", "pond_guards"],
        location_id: currLocation._id
      });
      context.commit("SET_POND_DEVICE_DETAILS", response.data.ponds);
    }
  }
};

export default pondInfoStore;
